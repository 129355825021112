<template>
  <div class="col">
    <!-- v-if="hasPermission('access_lci')" -->
    <div v-if="hasPermission('access_lci') || hasPermission('guest')">
      <card
        elevation="12"
        class="p-2"
        v-loading="$store.getters['user/usersLoad']"
      >
        <!-- Start New Design For Lci -->
        <div class="main_new_lci_design" v-if="checkUserScore == false">
          <div class="user_bg_img">
            <div class="new_user_background"></div>
            <span class="lines"></span>
            <img
              class="user_img"
              :src="image"
              draggable="false"
              alt="user image"
            />
          </div>
        </div>

        <!-- Start New Content -->
        <div class="lci_new_content" v-if="checkUserScore == false">
          <div class="login_what_patterns">
            <div class="text_patterns_box">
              <span class="pat_text text-uppercase">{{
                localization("thank you for subscription title")
              }}</span>
              <span class="pat_one"></span>
              <span class="pat_two"></span>
              <span class="pat_three"></span>
              <span class="pat_four"></span>
              <span class="pat_five"></span>
              <span class="pat_six"></span>
              <span class="pat_seven"></span>
              <span class="pat_eight"></span>
            </div>
            <div class="titles_lci">
              <h5>{{ localization("to get started") }}</h5>
              <p>
                {{ localization("Please click the button below") }}
              </p>
            </div>
            <button class="take_your_lci" @click="switchToTrueForLci">
              {{ localization("take your lci here") }}
            </button>
          </div>
        </div>
        <!-- Edn New Content -->

        <!-- Start New Cms Cards -->
        <h4
          class="main_new_title text-uppercase text-center"
          v-if="checkUserScore == false"
        >
          {{ localization("explore each feature") }}
        </h4>
        <div class="new_cms_cards" v-if="checkUserScore == false">
          <div class="lci_card_one" v-if="userLciCms != null">
            <div v-html="userLciCms[1].body"></div>
          </div>
          <div class="lci_card_one" v-if="userLciCms != null">
            <div v-html="userLciCms[2].body"></div>
          </div>
          <div class="lci_card_one" v-if="userLciCms != null">
            <div v-html="userLciCms[3].body"></div>
          </div>
          <div class="lci_card_one" v-if="userLciCms != null">
            <div v-html="userLciCms[4].body"></div>
          </div>
        </div>
        <!-- End New Cms Cards -->
        <!-- End New Design For Lci -->

        <h3
          class="mr-3"
          :class="{ 'text-right': isRtl() }"
          v-if="checkUserScore"
        >
          {{ localization("Learning Connections Inventory") }}
        </h3>

        <!-- Start Lci Instructions  -->
        <!-- <div class="mb-3" v-if="checkUserScore == false">
          <div class="mb-3 mt-3" v-if="userLciCms != null">
            <div v-html="userLciCms[0].body"></div>
          </div>
          <div>
            <button class="start_lci_button" @click="switchToTrueForLci">
              {{ localization("Start LCI") }}
            </button>
          </div>
        </div> -->
        <!-- End Lci Instructions -->
        <!-- Start Lci Cards  -->
        <div class="main_cards_container_lci" v-if="checkUserScore">
          <div class="row">
            <div
              class="col-lg-3 col-md-6 col-sm-12 mb-3 every_lci_card"
              v-if="lciCardsContent[0].body != null"
            >
              <div class="icons_text_wrapper">
                <div class="img_icons">
                  <img
                    class="img"
                    src="@/assets/images/icons/test.png"
                    alt="add"
                  />
                </div>
                <div>
                  <span v-html="lciCardsContent[0].body"> </span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 mb-3 every_lci_card"
              v-if="lciCardsContent[1].body != null"
            >
              <div class="icons_text_wrapper">
                <div class="img_icons">
                  <img
                    class="img"
                    src="@/assets/images/icons/target.png"
                    alt="add"
                  />
                </div>
                <div>
                  <span v-html="lciCardsContent[1].body"></span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 mb-3 every_lci_card"
              v-if="lciCardsContent[2].body != null"
            >
              <div class="icons_text_wrapper">
                <div class="img_icons">
                  <img
                    class="img"
                    src="@/assets/images/icons/edit.png"
                    alt="add"
                  />
                </div>
                <div>
                  <span v-html="lciCardsContent[2].body"></span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 mb-3 every_lci_card"
              v-if="lciCardsContent[3].body != null"
            >
              <div class="icons_text_wrapper">
                <div class="img_icons">
                  <img
                    class="img"
                    src="@/assets/images/icons/speaker.png"
                    alt="add"
                  />
                </div>
                <div>
                  <span v-html="lciCardsContent[3].body"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Lci Cards -->
        <div v-if="checkUserScore">
          <el-alert
            class="mb3 mt-2 text-center"
            v-if="this.$route.params.id"
            type="error"
            show-icon
            :closable="false"
            effect="dark"
          >
            <template>
              <p
                class="my-0"
                style="margin-bottom: 2px !important"
                :class="{ 'text-right': isRtl() }"
              >
                {{ localization("LCI Questions alert") }}
              </p>
            </template>
          </el-alert>
          <div
            class="row ml-0"
            justify="center"
            v-if="!$store.getters['user/questions'].length == 0"
          >
            <h3
              class="d-block mt-3 mb-2 w-100"
              :class="{ 'text-right': isRtl() }"
            >
              {{ localization("Part I") }}
            </h3>

            <!-- Part 1 Questions  -->
            <div class="col-md-12 px-lg-5 py-0">
              <div v-for="(question, i) in questionslist" :key="i">
                <!-- single Question  -->
                <!--//// Large screens and larger  -->
                <div v-if="question.type == 0" class="mb-3 d-none d-xl-block">
                  <div
                    class="d-flex mb-1 justify-content-between"
                    :class="{ 'flex-row-reverse': isRtl() }"
                  >
                    <div
                      class="d-flex"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <!-- index of question  -->
                      <div
                        style="gap:3px"
                        class="d-flex "
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span>{{ i + 1 }}</span> <span>-</span>
                      </div>
                      <!-- editable question input  -->
                      <textarea
                        :id="question.id"
                        class="
                      w-100
                      border-0
                      d-inline-block
                      mb-3
                      text-left
                      flex-grow-1
                    "
                        v-model="question.title"
                        @change="filter(question)"
                        v-if="editable == question.id && editable != null"
                      />
                      <!-- Question text  -->
                      <p
                        class="text-h4 mt-10 mb-3 text-left flex-grow-1"
                        v-else
                      >
                        {{ question.title }}
                      </p>
                    </div>
                    <div>
                      <!-- //? start text to speech  -->
                      <audio controls id="audio" class="d-none">
                        <source id="audio-source" type="audio/mp3" />
                      </audio>
                      <i
                        v-tooltip.top-center="
                          `${localization('Read out loud')}`
                        "
                        @click="text_speech_audio(question.title)"
                        class="fa fa-volume-up icons_style speech"
                        style="cursor: pointer"
                      ></i>
                      <!-- //? end text to speech  -->
                      <!-- Edit Button  -->
                      <!-- edit question  -->
                      <i
                        class="fa fa-edit mx-3 text-info icons_style"
                        style="cursor: pointer"
                        @click="toggle(question)"
                        v-if="!editAuthUser && editable != question.id"
                      ></i>
                      <!-- save changes  -->
                      <i
                        class="fa fa-check mx-3 text-success icons_style"
                        style="cursor: pointer"
                        @click="toggle(question)"
                        v-if="!editAuthUser && editable == question.id"
                      ></i>
                    </div>
                  </div>

                  <!-- <el-slider
                  class="slider-transparent"
                  :min="1"
                  :max="5"
                  :value="questions_options"
                  v-model="question.answer_id"
                  :show-tooltip="false"
                  :disabled="editAuthUser"
                >
                </el-slider>
                <div class="mb-5 d-flex justify-content-between">
                  <span style="" v-for="(answer, i) in questions_options" :key="i">{{
                    answer
                  }}</span>
                </div> -->
                  <div>
                    <el-radio-group
                      v-model="question.answer_id"
                      class="d-flex align-items-center flex-wrap"
                      @change="saveQuestion"
                    >
                      <el-radio-button
                        v-for="(answer, i) in question.questions_options"
                        :key="i"
                        :label="i + 1"
                        style="width: auto"
                        :class="
                          `${i != 0 && 'border-left'} mb-1 flex-grow-1  m-1`
                        "
                        :disabled="editAuthUser"
                      >
                        {{ answer }}
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </div>

                <!--///// extra small & small & Medium screens  -->
                <div v-if="question.type == 0" class="mb-3 d-xl-none">
                  <div class="mb-1 mobileQuestionContainer">
                    <!-- index of question  -->
                    <div>{{ i + 1 }} -</div>
                    <!-- editable question input  -->
                    <textarea
                      :id="question.id"
                      class="w-100 border-0 d-inline-block"
                      v-model="question.title"
                      @change="filter(question)"
                      v-if="editable == question.id && editable != null"
                    />
                    <!-- Question text  -->
                    <p class="text-h4" v-else>
                      {{ question.title }}
                    </p>
                    <!-- //? start text to speech  -->
                    <audio controls id="audio" class="d-none">
                      <source id="audio-source" type="audio/mp3" />
                    </audio>
                    <i
                      @click="text_speech_audio(question.title)"
                      class="fa fa-volume-up icons_style speech"
                      style="cursor: pointer"
                    ></i>
                    <!-- //? end text to speech  -->
                    <!-- Edit Button  -->
                    <!-- edit question  -->
                    <i
                      class="fa fa-edit mx-3 text-info"
                      style="cursor: pointer"
                      @click="toggle(question)"
                      v-if="!editAuthUser && editable != question.id"
                    ></i>
                    <!-- save changes  -->
                    <i
                      class="fa fa-check mx-3 text-success"
                      style="cursor: pointer"
                      @click="toggle(question)"
                      v-if="!editAuthUser && editable == question.id"
                    ></i>
                  </div>

                  <div>
                    <el-radio-group
                      v-model="question.answer_id"
                      size="medium"
                      @change="saveQuestion"
                      class="d-flex flex-column align-items-center"
                    >
                      <el-radio-button
                        v-for="(answer, i) in question.questions_options"
                        :key="i"
                        :label="i + 1"
                        style="width: auto"
                        :class="
                          `${i != 0 && 'border-left'} mb-1 flex-grow-1 w-100`
                        "
                        :disabled="editAuthUser"
                      >
                        {{ answer }}
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="d-block mt-3 w-100" :class="{ 'text-right': isRtl() }">
              {{ localization("Part II") }}
            </h3>
            <!-- Part 2 Questions  -->
            <div class="col-md-12 px-lg-5 py-0">
              <div v-for="(question, i) in questionslist" :key="i">
                <!-- single Question  -->
                <div v-if="question.type == 1">
                  <div
                    class="mobileQuestionContainer d-flex mb-1 justify-content-between"
                    :class="{ 'flex-row-reverse': isRtl() }"
                  >
                    <div
                      class="d-flex"
                      style="gap:3px"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <!-- index of question  -->
                      <div
                        class="d-flex "
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span>{{ i + 1 }}</span> <span>-</span>
                      </div>
                      <!-- editable question input  -->
                      <textarea
                        :id="question.id"
                        class="w-100 border-0 d-inline-block"
                        v-model="question.title"
                        @change="filter(question)"
                        v-if="editable == question.id && editable != null"
                      />
                      <!-- Question text  -->
                      <p class="text-h4" v-else>
                        {{ question.title }}
                      </p>
                    </div>
                    <!-- Edit Button  -->
                    <div>
                      <!-- edit question  -->
                      <i
                        class="fa fa-edit mx-3 text-info"
                        style="cursor: pointer"
                        @click="toggle(question)"
                        v-if="!editAuthUser && editable != question.id"
                      ></i>
                      <!-- save changes  -->
                      <i
                        class="fa fa-check mx-3 text-success"
                        style="cursor: pointer"
                        @click="toggle(question)"
                        v-if="!editAuthUser && editable == question.id"
                      ></i>
                    </div>
                  </div>
                  <div>
                    <fg-input
                      :label="`${localization('Answer')}`"
                      :class="{ 'text-right': isRtl() }"
                    >
                      <textarea
                        v-model="question.answer_text"
                        class="form-control border-0"
                        :placeholder="`${localization('Answer')}`"
                        rows="2"
                        :disabled="editAuthUser"
                        :class="{ 'text-right': isRtl() }"
                      ></textarea>
                    </fg-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center m-auto">
              <button
                type="button"
                @click="save"
                class="btn submit_lci_buttons btn-round btn-wd px-4 py-3 mt-5"
                :disabled="editAuthUser"
              >
                {{ localization("Submit LCI") }}
              </button>
            </div>
          </div>
        </div>
      </card>
    </div>
    <!-- v-else -->
    <div v-else>
      <h2 class="text-dark text-center">
        {{
          localization("update your plan") != false
            ? localization("update your plan")
            : "Kindly update your plan to access this page"
        }}
      </h2>
    </div>
  </div>
</template>

<script>
import { Slider, Tag } from "element-ui";
import LAlert from "src/components/Alert";

import Swal from "sweetalert2";
// annual@annual.com
// an@an.com 123456
// annualtwo@two.com
export default {
  components: {
    [Slider.name]: Slider,
    LAlert
  },
  data: () => ({
    testval: [],
    questionslist: [],
    arrOfIDs: [],
    modals: {
      basic: false,
      centered: false,
      custom: false,
      confirm: false
    },
    answers: [
      "Never Ever",
      "Almost Never",
      "Sometimes",
      "Almost Always",
      "Always"
    ],
    one: 0,
    result: [],
    editable: false,
    answersall: {},
    userLciCms: null,
    checkUserScore: false,
    checkUserScoreTwo: false
  }),
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    editAuthUser() {
      // prevent admin from editing user PLC
      return this.$route.params.id != undefined;
    },
    lciCardsContent() {
      return this.$store.getters["user/get_lci_cards"];
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    image() {
      if (this.auth().image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${
          this.auth().image
        }`;
      }
      return "/static/img/lml-swerl.jpg";
    }
    // checkUserScoreAlways() {
    //   return this.userScore.confluence == null &&
    //     this.userScore.precision == null &&
    //     this.userScore.sequence == null &&
    //     this.userScore.technical_reasoning == null
    //     ? (this.checkUserScore = false)
    //     : (this.checkUserScore = true);
    // },

    // checkUserScoreAlwaysTwo() {
    //   return this.userScore.confluence != null &&
    //     this.userScore.precision != null &&
    //     this.userScore.sequence != null &&
    //     this.userScore.technical_reasoning != null
    //     ? (this.checkUserScoreTwo = true)
    //     : (this.checkUserScoreTwo = false);
    // }
  },

  mounted() {
    this.$store.dispatch("user/get_questions", { id: this.id }).then(_ => {
      this.questionslist = this.$store.state.user.questions;
      for (var i in this.questionslist) {
        this.questionslist[i].title = this.questionslist[i].question_body
          ? this.questionslist[i].question_body
          : this.questionslist[i].title;
      }
    });
    this.$store.dispatch("user/handlerLciCards");
    this.$store.dispatch("user/handlerUserLciCms").then(response => {
      this.userLciCms = response.lci_instructions;
    });
    this.$store.dispatch("user/user", this.id).then(() => {
      if (
        this.userScore.confluence == null &&
        this.userScore.precision == null &&
        this.userScore.sequence == null &&
        this.userScore.technical_reasoning == null
      ) {
        this.checkUserScore = false;
      } else {
        this.checkUserScore = true;
      }
    });
  },
  methods: {
    filter(ques) {
      this.arrOfIDs.push(ques.id);
    },
    save() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      if (allQuestionsAnswered) {
        for (let index = 0; index < this.questionslist.length; index++) {
          const element = this.questionslist[index];

          if (element.answer_text) {
            element.answer_id = null;
          } else if (element.answer_id) {
            element.answer_text = null;
          }
          this.result[index] = {
            id: element.id,
            answer_id: element.answer_id,
            question_id: element.question_id,
            question_body:
              this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
            answer_text: element.answer_text
          };
        }
        // check if user answered all question with same answers
        // variables to check if value changed
        let valueChoiceChanged = false,
          valueTextChanged = false;
        // loop to check there are at least one differnet answer at both type of questions
        for (let i = 1; i < this.result.length; i++) {
          // current & previous answers of both type of questios
          let answerId = this.result[i].answer_id,
            answerTxt = this.result[i].answer_text,
            prevAnswerId = this.result[i - 1].answer_id,
            prevAnswerTxt = this.result[i - 1].answer_text;
          if (answerId === null) {
            // check for text questions
            // null coz when we move from multichoice to text questions
            if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
              // if one value changed
              valueTextChanged = true;
          } else {
            // check for Multichoice quesitons
            if (answerId !== prevAnswerId) valueChoiceChanged = true;
          }
        }
        // only execute if answers are unique
        if (valueChoiceChanged && valueTextChanged) {
          this.answersall = {
            answers: this.result,
            status: true
          };
          this.$store
            .dispatch("user/save_answers", {
              query: this.answersall
            })
            .then(response => {
              if (response.status == "done") {
                // this.$router.push("/profile");
              }
            })
            .catch(err => {
              if (err.response.data.error == "LCI error message") {
                Swal.fire({
                  text: `${this.localization("LCI error message")}`,
                  type: "error",
                  icon: "error",
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              }
            });
        } else {
          Swal.fire({
            // text: "Kindly input uniques answers",
            text: `${this.localization("Unique answers")}`,
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000
          });
        }
      } else {
        Swal.fire({
          // text: "Please answer all questions",
          text: `${this.localization("Answer all questions")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });
      }
    },
    toggle(question) {
      this.editable = this.editable ? false : question.id;
    },
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },

    saveQuestion() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      // if (allQuestionsAnswered) {
      for (let index = 0; index < this.questionslist.length; index++) {
        const element = this.questionslist[index];

        if (element.answer_text) {
          element.answer_id = null;
        } else if (element.answer_id) {
          element.answer_text = null;
        }
        this.result[index] = {
          id: element.id,
          answer_id: element.answer_id,
          question_id: element.question_id,
          question_body:
            this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
          answer_text: element.answer_text
        };
      }
      // check if user answered all question with same answers
      // variables to check if value changed
      let valueChoiceChanged = false,
        valueTextChanged = false;
      // loop to check there are at least one differnet answer at both type of questions
      for (let i = 1; i < this.result.length; i++) {
        // current & previous answers of both type of questios
        let answerId = this.result[i].answer_id,
          answerTxt = this.result[i].answer_text,
          prevAnswerId = this.result[i - 1].answer_id,
          prevAnswerTxt = this.result[i - 1].answer_text;
        if (answerId === null) {
          // check for text questions
          // null coz when we move from multichoice to text questions
          if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
            // if one value changed
            valueTextChanged = true;
        } else {
          // check for Multichoice quesitons
          if (answerId !== prevAnswerId) valueChoiceChanged = true;
        }
      }
      // only execute if answers are unique
      if (valueChoiceChanged) {
        this.answersall = {
          answers: this.result,
          status: false
        };
        this.$store
          .dispatch("user/save_answers", {
            query: this.answersall
          })
          .then(response => {});
      } else {
        Swal.fire({
          text: `${this.localization("Unique answers")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000
        });
      }
      // } else {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Please answer all questions",
      //     icon: "error",
      //     showCancelButton: false,
      //     showConfirmButton: false,
      //     timer: 3000
      //   });
      // }
    },
    switchToTrueForLci() {
      this.checkUserScore = !this.checkUserScore;
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style>
.mobileQuestionContainer {
  display: grid;
  grid-template-columns: 2rem auto 2rem;
}
textarea {
  resize: none;
}
.fa {
  width: 0.5rem;
}
b {
  font-weight: 700;
}
span.el-radio-button__inner {
  width: 100%;
}
/* .el-radio-button.el-radio-button--medium span {
  width: 100%;
} */
</style>

<style lang="scss" scoped>
.start_lci_button {
  background: #00563f;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  border-color: #00563f;
  min-width: 150px;
}
.submit_lci_buttons {
  background: #00563f;
}
.main_cards_container_lci {
  .every_lci_card {
    .icons_text_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      background: #00563f;
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      min-height: 177px;
      // min-height: 145px;
    }
    .img_icons {
      .img {
        max-width: 50px;
      }
    }
  }
}
::v-deep .el-radio-button {
  &.is-active {
    .el-radio-button__inner {
      background: #00563f;
      border-color: #00563f;
    }
  }
}
.icons_style {
  background-color: #eee;
  font-size: 14px;
  width: 30px;
  height: 30px;
  // box-shadow: $simple-shadow;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  cursor: pointer;
  color: #777;
  transition: 0.3s;
  &.speech {
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// Start New Design For Lci
$just_theme_color: #00563f;
.main_new_lci_design {
  height: 150px;
  .user_bg_img {
    height: 150px;
    position: relative;
    .new_user_background {
      background-color: $just_theme_color;
      height: 100%;
      margin: auto;
    }
    .lines {
      width: 90%;
      border-radius: 50%;
      border: 3px solid #ffffff4d;
      bottom: -80px;
      position: absolute;
      height: 135px;
      left: 5%;
      display: block;
    }
    .user_img {
      width: 110px;
      height: 110px;
      position: absolute;
      bottom: -62px;
      left: calc(50% - 62px);
      border-radius: 50%;
      border: 2px solid #eee;
    }
  }
}
// Start Lci Cards
.main_new_title {
  margin: 40px auto 15px;
  color: #777;
  font-weight: bold;
  font-size: 20px;
}
.new_cms_cards {
  margin: 0 auto 20px;
  display: flex;
  width: 80%;

  gap: 4%;
  justify-content: space-between;
  flex-wrap: wrap;
  .lci_card_one {
    background-color: $just_theme_color;
    color: #fff;
    width: 48%;
    min-height: 140px;
    border-radius: 20px;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }
}
// Start New Content
.lci_new_content {
  $width: 25%;
  .login_what_patterns {
    margin-top: 90px;
    .text_patterns_box {
      width: 53%;
      margin: auto;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      border: 5px solid transparent;
      color: #777;
      position: relative;
      padding: 10px;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 80%;
        font-size: 1.5rem;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 100%;
        font-size: 1.5rem;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 100%;
        font-size: 0.9rem;
      }
      // pat_text
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #39c;
      }
      &::after {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #c36;
      }

      .pat_one {
        position: absolute;
        top: -5px;
        left: 0;
        height: 5px;
        width: $width;
        background-color: #39c;
      }

      .pat_two {
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 5px;
        width: $width;
        background-color: #39c;
      }
      .pat_three {
        position: absolute;
        top: -5px;
        left: $width;
        height: 5px;
        width: $width;
        background-color: #3c3;
      }
      .pat_four {
        position: absolute;
        bottom: -5px;
        left: $width;
        height: 5px;
        width: $width;
        background-color: #3c3;
      }

      .pat_five {
        position: absolute;
        top: -5px;
        left: $width * 2;
        height: 5px;
        width: $width;
        background-color: #f93;
      }
      .pat_six {
        position: absolute;
        bottom: -5px;
        left: $width * 2;
        height: 5px;
        width: $width;
        background-color: #f93;
      }

      .pat_seven {
        position: absolute;
        top: -5px;
        left: $width * 3;
        height: 5px;
        width: $width;
        background-color: #c36;
      }
      .pat_eight {
        position: absolute;
        bottom: -5px;
        left: $width * 3;
        height: 5px;
        width: $width;
        background-color: #c36;
      }
    }
    .titles_lci {
      width: 51%;
      margin: auto;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      border: 3px solid $just_theme_color;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: 0;
      color: #777;
      position: relative;
      padding: 10px;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 78%;
        font-size: 1.5rem;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 98%;
        font-size: 1.5rem;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 98%;
        font-size: 0.9rem;
      }
      h5 {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      p {
        margin: 0;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    .take_your_lci {
      width: 51%;
      display: block;
      margin: 15px auto 0;
      text-align: center;
      background-color: $just_theme_color;
      color: #fff;
      padding: 7px;
      font-weight: bold;
      border-radius: 7px;
      font-size: 14px;
      text-transform: uppercase;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 78%;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 98%;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 98%;
      }
    }
  }
}
</style>
